//This is a pilot component for the 3/2023 GoB release
import React from 'react';
import {ErrorBoundary} from "../error/ErrorBoundary";
import {Card, Row} from "react-bootstrap";
import useWindowDimensions from "../../hooks/windowState";
import {LoginPanelFooter} from "./LoginPanelFooter";
import {LoginForm} from "./LoginForm";
import {PilotContent} from "./PilotContent";
import {Icon} from "@blueprintjs/core";
import {CardTitle} from "../../styles/Card";

export const isLoggedIn = () => {
    let item = localStorage.getItem("gob");
    return item && JSON.parse(item).state === "logged_in" && JSON.parse(item).user.email && JSON.parse(item).token;
}

const SignIn = () => {
    const {width} = useWindowDimensions();
    const widthCalc = width > 1000 ? "w-50" : "w-100";
    if (isLoggedIn())
        return <></>
    return (
        <div data-testid={"sign-in"}>
            <Row sm={8} className={"justify-content-center mx-auto"}>
                <Card className={"mt-5 mx-auto"}
                      data-testid={"content-pane-for-nav"}>
                    <Card.Header className={widthCalc + " mb-2 mx-auto"}></Card.Header>
                    <CardTitle className={"mx-auto"} style={{fontFamily: "Alkatra, cursive", fontSize: "12px"}}>
                        <Icon icon="log-in" className={"p-1"} size={20}/>Sign In</CardTitle>
                    <LoginForm className={widthCalc + " mx-auto"}/>
                    <LoginPanelFooter className={widthCalc + " mx-auto text-center "}/>
                </Card>
            </Row>
        </div>
    );
};

export const Pilot = (_props: {}) => {
    return (
        <div data-testid={"pilot"}>
            <ErrorBoundary fallback={<div>Something went wrong, error reported!</div>}>
                {isLoggedIn() &&
                    <PilotContent/>
                }
                {!isLoggedIn() && <SignIn/>}
            </ErrorBoundary>
        </div>
    )
}

