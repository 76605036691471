import {Card, Elevation} from '@blueprintjs/core'
import React from 'react'
import useWindowDimensions from "../../hooks/windowState";

export const ReadAbout = () => {
    const {width} = useWindowDimensions();
    const widthCalc = width > 1000 ? "w-50" : "w-100";

    return (
        <Card interactive={true} className={widthCalc + " mx-auto h-75"} elevation={Elevation.FOUR}>
        </Card>)
}