import React from "react";
import {CardBody} from "../../styles/Card";

export const LoginPanelFooter = (props: { className: string }) => <CardBody className={props.className}
                                                                            data-testid="login-overlay-content">
    We're thrilled that you're interested in our invite-only pilot for 🪷Greats of Bharatha!
    <br/>We know that you're invaluable, so we'd love to have you be a part of this exclusive pilot.
    To request an invite, simply shoot us an email at ganesh@greatsofbharatha.in, and we'll send you
    instructions for signing up in the next few hours.
    <br/>Thanks for understanding, as this is an invite-only-pilot!
    Look forward to reading from you soon!
</CardBody>;