import React, {useEffect, useRef, useState} from "react";
import {Form, Toast, ToastBody, ToastContainer} from "react-bootstrap";
import axios from "axios";
import {Button} from "../../styles/Button";

export const LoginForm =
    (props: { className: string }) => {
        const formRef = useRef<HTMLFormElement>(null);
        const [formData, setFormData] = useState({otp: '', email: ''});
        const [loginFailed, setLoginFailed] = useState<boolean | null>(null);
        const [showError, setShowError] = useState(false);
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const {name, value} = event.target;
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        };
        const handleOtpValidity = (event: React.ChangeEvent<HTMLInputElement>) => {
            const input = event.target;
            if (input.value.length > 0 && !validOtp.test(input.value)) {
                input.setCustomValidity('Please check T-OTP format');
            } else {
                input.setCustomValidity('');
            }
        };
        const validOtp = /^[0-9]{1,6}$/
        const login = (event: React.FormEvent<HTMLFormElement>) => {
            if (!(formRef.current && formRef.current.checkValidity())) {
                event.preventDefault();
                return;
            } else {
                event.preventDefault();
                axios.post(process.env.REACT_APP_API_ENDPOINT_PREFIX + "api/v1/jwt", {
                        name: formData.email,
                        email: formData.email,
                        totp: formData.otp
                    }
                )
                    .then(function (response) {
                        setLoginFailed(false);
                        localStorage.setItem("gob", JSON.stringify({
                            state: "logged_in",
                            token: response.data.jwt,
                            user: {email: formData.email}
                        }))
                        window.location.reload();
                    })
                    .catch(function (_error) {
                        setLoginFailed(true);
                        setShowError(true);
                    });
            }
        };
        useEffect(() => {
            if (loginFailed && showError) {
                setTimeout(() => setShowError(false), 5000);
            }
        }, [loginFailed, showError]);

        return <Form ref={formRef} data-testid="login-form" className={"text-center"} onSubmit={login}>
            <Form.Group className="mb-3" controlId="formEmail">
                <Form.Control className={props.className}
                              size="sm"
                              data-testid="email-input"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              type="email"
                              required
                              placeholder="Enter email"
                              title="Should be a proper email address"
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formOtp">
                <Form.Control className={props.className}
                              size="sm"
                              name="otp"
                              data-testid="otp-input"
                              type="password"
                              required
                              pattern={validOtp.source}
                              value={formData.otp}
                              onChange={handleChange}
                              placeholder="Time based OTP"
                              onInput={handleOtpValidity}
                />

            </Form.Group>
            <Button variant="primary" type="submit" disabled={showError} data-testid="login">
                Login
            </Button>
            {showError &&
                <ToastContainer position={"top-center"}>
                    <Toast bg="warning" onClose={() => setShowError(false)} delay={5000} autohide>
                        <Toast.Header>Login Failed</Toast.Header>
                        <ToastBody>Please check email/otp and try again!</ToastBody>
                    </Toast>
                </ToastContainer>}
        </Form>;
    };