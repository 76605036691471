import styled from "styled-components";
import {Card} from "react-bootstrap";
//style={{fontFamily: "Alkatra, cursive", fontSize: "12px"}}
export const CardBody = styled(Card.Body)`
  font-family: Alkatra, cursive;
  font-size: 12px;
  color: rgb(119, 104, 123);
`
export const CardTitle = styled(Card.Title)`
  font-family: Alkatra, cursive;
  font-size: 12px;
  color: rgb(119, 104, 123);
`