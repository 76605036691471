import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import logo from "../../images/LogoV4.png";
import React from "react";
import {content} from "./NavContentPane";

const CustomNavLink = (props: { nKey: string }) => {
    return <Nav.Link
        style={{fontSize: "12px"}}
        data-testid={props.nKey}
        eventKey={props.nKey}>{props.nKey}</Nav.Link>;
};

export const NavBar = (props: { onSelect: (eventKey: (string | null)) => void }) =>
    <Navbar collapseOnSelect style={{backgroundColor: "rgb(119,104,123)"}} expand="lg"
            onSelect={props.onSelect}
            variant="dark"
    >
        <Container color={"white"}>
            <Navbar.Brand href={"#home"}>
                <Row>
                    <Col>
                        <img data-testid={"logo"} src={logo} alt={"logo"} width={"150"} height={"25"}
                             className={"d-inline-block align-bottom"}/>
                    </Col>
                </Row>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    {Object.keys(content).map((key: string) => {
                        return <Nav.Item style={{height: "20px", marginBottom: "0.5rem"}} key={key + "ni"}>
                            <CustomNavLink key={key} nKey={key}/>
                        </Nav.Item>
                    })}
                    {localStorage.getItem("gob") && <Nav.Item key={"Log out ni"}>
                        <CustomNavLink key={"Log out"} nKey={"Log out"}/>
                    </Nav.Item>
                    }
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>;