import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Error404} from "./components/error/404";
import {Pilot} from "./components/pilot/Pilot_0323";
import {People, Vision, Wassup} from "./pages/Pages";
import {Logout} from "./components/pilot/Logout";
import {ReadAbout} from "./components/pilot/ReadAbout";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>}/>
                <Route path="/vision" element={<App embed={<Vision/>}/>}/>
                <Route path="/people" element={<App embed={<People/>}/>}/>
                <Route path="/What's Happening" element={<App embed={<Wassup/>}/>}/>
                <Route path="/Pilot" element={<App embed={<Pilot/>}/>}/>
                <Route path="/Log out" element={<App embed={<Logout/>}/>}/>
                <Route path="/pilot/read-about/sheshadri" element={<App embed={<ReadAbout/>}/>}/>
                <Route path="*" element={<Error404/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
