import {Card, Col, Row, Spinner} from "react-bootstrap";
import sheshadri_swamigal from "../../images/sheshadri_swamigal.jpg"
import {isLoggedIn} from "./Pilot_0323";
import useWindowDimensions from "../../hooks/windowState";
import {useNavigate} from "react-router-dom";
import {Button} from "../../styles/Button";
import {CardBody, CardTitle} from "../../styles/Card";

export const PilotContent = () => {
    const {width} = useWindowDimensions();
    const widthCalc = width > 1000 ? "w-50" : "w-100";
    const navigate = useNavigate();
    if (!isLoggedIn())
        return <></>
    return (
        <Card className={widthCalc + " mx-auto"} data-testid={"pilot-content-pane"}>
            <Card.Img data-testid={"sheshadri-swamigal-img"} src={sheshadri_swamigal} height={"100px"} width={"100px"}
                      style={{objectFit: 'contain'}}></Card.Img>
            <CardTitle className={"text-center"}>Sheshadri Swamigal</CardTitle>
            <CardBody className={"text-center"}>Sheshadri
                Swamigal was a great saint
                and philosopher, who inspired many people with his teachings
                and enlightened them with his wisdom.</CardBody>
            <Row>
                <Col>
                    <Button data-testid={"read-about-button"} variant="primary"
                            onClick={() =>
                                navigate("/pilot/read-about/sheshadri")}>Read
                        about Sheshadri
                        Swamigal</Button>
                </Col>
                <Col>
                    <Button data-testid={"ask-and-learn-button"} disabled={true} variant="primary">Ask and learn about
                        Sheshadri
                        Swamigal</Button>
                </Col>
            </Row>
            <Row className={"mt-2"}>
                <Col>
                    <Button data-testid={"places-learn-button"} disabled={true} variant="primary">Places associated with
                        Sheshadri
                        Swamigal</Button>
                </Col>
                <Col>
                    <Button data-testid={"temples-learn-button"} disabled={true} variant="primary">Temples associated
                        with
                        Sheshadri
                        Swamigal</Button>
                </Col>
            </Row>
            <Row style={{fontSize: "8px"}} className={"text-center"}>
                <Col>Please bear with me, while the pilot is being built...
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /></Col>
            </Row>
        </Card>
    )
};