import React from 'react';
import '../styles/App.css';
import {Container, Navbar, Row} from "react-bootstrap";
import {NavBar} from "./nav/TopNav";
import {ErrorBoundary} from "./error/ErrorBoundary";
import {useNavigate} from "react-router-dom";

const App = (props: { embed?: React.ReactNode }) => {
    const navigate = useNavigate();
    const handleSelect = (eventKey: string | null) => eventKey && navigate("/" + eventKey);
    return (
        <ErrorBoundary fallback={<div>Something went wrong, error reported!</div>}>
            <Container fluid>
                <Row>
                    <NavBar onSelect={handleSelect}/>
                </Row>
                <Row className="vh-100 mx-auto">
                    <Container className="h-100 mx-auto">
                        {props.embed && <Row className={"h-100"}>{props.embed}</Row>}
                    </Container>
                </Row>
                <Row>
                    <Navbar fixed={"bottom"} style={{height: "40px"}} className={"justify-content-center"}
                            expand={"lg"}>
                        <Navbar.Text style={{paddingBottom: "0px", paddingTop: "0px"}}> 2023 🪷 Greats of
                            Bharatha</Navbar.Text>
                    </Navbar>
                </Row>
            </Container>
        </ErrorBoundary>

    );
};

export default App;
