// noinspection JSNonASCIINames

import {Row} from "react-bootstrap";
import React from "react";

export const content: any = {
    "Vision": {
        title: "Statement of Vision",
        body: "Our vision is to create a digital platform that celebrates the rich cultural heritage of Bharatha, highlighting lives and achievements of its great figures contemporary and  history. " +
            "<br/> We aim to provide a dynamic and engaging platform that inspires people to learn about the greats of India, their contributions to the world, and the values and principles that guided them." +
            "<br/> Doing so, we hope to foster a greater appreciation for India's cultural legacy and its ongoing impact on all our lives, while also inspiring future generations to emulate the greats of India in their own lives.",
    },
    "People": {
        title: "People",
        body: "Greats of Bharatha is a one-person venture dedicated to celebrating India's cultural heritage by highlighting the lives and achievements of its great figures throughout history. " +
            "<br/>Get in touch at : ganesh@greatsofbharatha.in",
    },
    "What's Happening": {
        title: "What's Happening",
        body: "Just starting to build a pilot around Sheshadri Swamigal, as a Great of Bharatha. Stay tuned for more updates!",
    },
    "Pilot": {
        title: "🧑‍✈️Pilot",
    },
}
export const ContentPane = (props: { selected: string }) => {
    switch (props.selected) {
        case "":
            return <></>
        default:
            return (<Row sm={8} className={"mt-5"}>
                <div data-testid={"content-pane-for-nav"}
                     style={{fontFamily: "Kalam, cursive", fontSize: "12px"}}
                     dangerouslySetInnerHTML={{__html: content[props.selected].body}}></div>
            </Row>);
    }

};